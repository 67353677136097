<template>
  <div :class="'mobile-input' + (type == 'textarea' ? ' textarea' : '')" v-if="!disabled">
    <div class="title">
      {{ title }}
    </div>
    <div class="content">
      <div class="text" @click="click">
        <textarea v-if="type == 'textarea'" v-model="content" :disabled="isDisabled" :placeholder="placeholder" />
        <input v-else v-model="content" :disabled="isDisabled" :placeholder="placeholder" />
      </div>
      <div class="line"></div>
    </div>
  </div>
  <div class="mobile-input-disabled" v-else>
    <div class="title">
      {{ title }}
    </div>
    <div class="content">
      <div class="text">{{ content }}</div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  model: {
    prop: 'initialContent',
    event: 'change'
  },
  props: {
    disabled: Boolean,
    title: String,
    pickerTitle: {
      type: String,
      default: "请选择",
    },
    initialContent: String,
    placeholder: String,
    type: {
      type: String,
      default: "input", //input/select/date/time/datetime/cascade/segment/textarea/others
    },
    name: String,
    index: String,
    contentChanged: Function,
    options: {
      type: Array,
      default: () => {
        return [{ text: "text", value: "value" }];
      },
    },
    otherClicked: Function,
    cascadeData: {
      type: Array,
      default: () => {
        return [{ text: "text", value: "value" }];
      },
    },
    segmentData: {
      type: Array,
      default: () => {
        return [
          {
            is: 'cube-date-picker',
            title: '开始时间',
            min: new Date(2020, 1, 1),
            max: new Date(2030, 12, 31),
            value: new Date(),
          },
          {
            is: 'cube-date-picker',
            title: '结束时间',
            min: new Date(2020, 1, 1),
            max: new Date(2030, 12, 31),
            value: new Date(),
          }
        ];
      },
    }
  },
  computed: {
    isDisabled: function () {
      // if (this.type === "input" || this.type === "textarea") {
      //   return this.disabled;
      // } else {
      //   return true;
      // }
      return false;
    },
  },
  data: function () {
    return {
      content: this.initialContent,
      value: "",
    };
  },
  methods: {
    setContent: function (content) {
      this.content = content;
    },
    click: function () {
      if (this.disabled) {
        return;
      }
      console.log(this.type)
      if (this.type !== "input" && this.type !== "textarea") {
        if (this.type === "others") {
          if (this.otherClicked) {
            this.otherClicked(this.index, this);
          }
        } else {
          this.showPicker();
        }
      }
    },
    showPicker: function () {
      if (this.type === "select") {
        if (!this.picker) {
          this.picker = this.$createPicker({
            title: this.pickerTitle,
            data: [this.options],
            onSelect: (selectedVal, selectedIndex, selectedText) => {
              this.value = selectedVal[0];
              this.content = selectedText[0];
            },
          });
        }
        this.picker.show();
      } else if (this.type === "date") {
        if (!this.datePicker) {
          this.datePicker = this.$createDatePicker({
            title: this.pickerTitle,
            min: new Date(2020, 1, 1),
            max: new Date(2025, 12, 31),
            value: new Date(),
            onSelect: (date) => {
              this.content = dayjs(date).format("YYYY-MM-DD");
            },
          });
        }
        this.datePicker.show();
      } else if (this.type === "time") {
        if (!this.timePicker) {
          this.timePicker = this.$createDatePicker({
            title: this.pickerTitle,
            min: [0, 0, 0],
            max: [23, 59, 59],
            value: new Date(),
            startColumn: "hour",
            minuteStep: 30,
            onSelect: (date) => {
              this.content = dayjs(date).format("HH:mm");
            },
          });
        }
        this.timePicker.show();
      } else if (this.type === "datetime") {
        if (!this.dateTimePicker) {
          this.dateTimePicker = this.$createDatePicker({
            title: this.pickerTitle,
            min: new Date(2020, 1, 1, 0, 0, 0),
            max: new Date(2025, 12, 31, 23, 59, 59),
            value: new Date(),
            columnCount: 5,
            minuteStep: 30,
            onSelect: (date) => {
              this.content = dayjs(date).format("YYYY-MM-DD HH:mm");
            },
          });
        }
        this.dateTimePicker.show();
      } else if (this.type === "cascade") {
        if (!this.cascadePicker) {
          this.cascadePicker = this.$createCascadePicker({
            title: this.pickerTitle,
            data: this.cascadeData,
            onSelect: (selectedVal, selectedIndex, selectedText) => {
              this.value = selectedVal.join(',');
              this.content = selectedText.join(' - ');
            },
          });
        }
        this.cascadePicker.show();
      } else if (this.type === "segment") {
        if (!this.segmentPicker) {
          this.segmentPicker = this.$createSegmentPicker({
            data: this.segmentData,
            onSelect: (selectedDates) => {
              this.value = dayjs(selectedDates[0]).format("YYYY-MM-DD HH:mm:ss") + ',' + dayjs(selectedDates[1]).format("YYYY-MM-DD HH:mm:ss");
              this.content = dayjs(selectedDates[0]).format("YYYY-MM-DD") + ' ~ ' + dayjs(selectedDates[1]).format("YYYY-MM-DD");
            },
            onNext: (i, selectedDate) => {
              // 结束日期：默认加一周
              this.segmentData[1].value = dayjs(selectedDate).add(7, 'day').toDate()
              this.segmentData[1].min = selectedDate
              if (i === 0) {
                this.segmentPicker.$updateProps({
                  data: this.segmentData
                })
              }
            }
          });
        }
        this.segmentPicker.show();
      }
    },
  },
  watch: {
    content: function (val) {
      if (this.contentChanged) {
        if (["input", "textarea", "date", "time", "datetime"].includes(this.type)) {
          if (this.index) {
            this.contentChanged(this.name, this.index, val);
          }
          else {
            this.contentChanged(this.name, val);
          }
        } else if (["select", "cascade", "segment"].includes(this.type)) {
          if (this.index) {
            this.contentChanged(this.name, this.index, this.value, this.content);
          }
          else {
            this.contentChanged(this.name, this.value, this.content);
          }
        }
      }
      this.$emit("change", val);
    },
  },
};
</script>
